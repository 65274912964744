import { createStore } from 'redux'
import { encpt, decpt } from './controllers/EncDec'

const initialState = {
  sidebarShow: true,
  statusLogin: false,
  loadingapp: false,
  pasien: { peserta: null, selectedpl: null, selecteddk: null },
  polies: { lst: [] },
  dokter: { lst: [] },
  detailant: {},
  detailkun: {},
  menu: { url: null, back: null, next: null },
  user: { token: '' },
}

const changeState = (state = initialState, { type, ...rest }) => {
  const user = localStorage.getItem('user')
  const usrp = JSON.parse(user)
  const hash = usrp?.token?.replace('console ', '')
  const parser = (code) => {
    const de = decpt({ key: code, typ: 'session', hash }) || ''
    return de.includes(hash) ? JSON.parse(de) : sessionStorage.removeItem(code)
  }
  var newpasien = {}
  var newant = {}
  switch (type) {
    case 'set':
      return { ...state, ...rest }
    case 'setclear':
      return initialState
    case 'delpasien':
      sessionStorage.removeItem('::ps')
      return { ...state, pasien: rest['pasien'] }
    case 'delsant':
      sessionStorage.removeItem('::at')
      return { ...state, detailant: {} }
    case 'moveMenu':
      sessionStorage.removeItem('::mn')
      return { ...state, menu: rest['menu'] }
    case 'setMenu':
      encpt({
        st: JSON.stringify(rest),
        key: '::mn',
        typ: 'session',
        hash,
      })
      return { ...state, menu: rest['menu'] }
    case 'setpasien':
      encpt({
        st: JSON.stringify(rest),
        key: '::ps',
        typ: 'session',
        hash,
      })
      return { ...state, pasien: rest['pasien'] }
    case 'setpolies':
      encpt({
        st: JSON.stringify(rest['polies']),
        key: '::pl',
        typ: 'session',
        hash,
      })
      return { ...state, polies: rest['polies'] }
    case 'setdokter':
      encpt({
        st: JSON.stringify(rest['dokter']),
        key: '::dk',
        typ: 'session',
        hash,
      })
      return { ...state, dokter: rest['dokter'] }
    case 'setslecteddokt':
    case 'setslectedpoli':
      newpasien = {
        ...state.pasien,
        selectedpl: rest['pl'] || state.pasien.selectedpl,
        selecteddk: rest['dk'] || state.pasien.selecteddk,
      }
      if (rest['dk'] === null) {
        delete newpasien.selecteddk
      }
      if (rest['pl'] === null) {
        delete newpasien.selectedpl
      }
      encpt({
        st: JSON.stringify({ pasien: newpasien }),
        key: '::ps',
        typ: 'session',
        hash,
      })
      return { ...state, pasien: newpasien }
    case 'setdetailantrian':
      newant = {
        ...rest['detailant'],
        nama: state.pasien?.peserta?.nama || '',
        sex: state.pasien?.peserta?.sex === 'L' ? 'Laki - Laki' : 'Perempuan',
        dokter: state.pasien?.selecteddk?.doknama || '',
        poli: state.pasien?.selectedpl?.polnama || '',
        tglkun: state.detailkun?.tanggalperiksa || '',
        nobpjs: state.pasien?.peserta?.noKartu || '',
      }
      encpt({
        st: JSON.stringify(newant),
        key: '::at',
        typ: 'session',
        hash,
      })
      sessionStorage.removeItem('::kj')
      sessionStorage.removeItem('::ps')
      sessionStorage.removeItem('::pl')
      sessionStorage.removeItem('::dk')
      return { ...state, detailant: newant, detailkun: {}, pasien: initialState.pasien }
    case 'setdetailkunjungan':
      encpt({
        st: JSON.stringify(rest['detailkun']),
        key: '::kj',
        typ: 'session',
        hash,
      })
      return { ...state, detailkun: rest['detailkun'], loadingapp: false }
    default:
      return {
        ...state,
        polies: !!decpt({ key: '::pl', typ: 'session', hash }) ? parser('::pl') : { lst: [] },
        dokter: !!decpt({ key: '::dk', typ: 'session', hash }) ? parser('::dk') : { lst: [] },
        pasien: !!decpt({ key: '::ps', typ: 'session', hash })
          ? parser('::ps')?.pasien
          : { peserta: {} },
        menu: !!decpt({ key: '::mn', typ: 'session', hash }) ? parser('::mn')?.menu : { url: {} },
        detailant: !!decpt({ key: '::at', typ: 'session', hash }) ? parser('::at') : {},
        detailkun: !!decpt({ key: '::kj', typ: 'session', hash }) ? parser('::kj') : {},
        user: localStorage.getItem('user')
          ? JSON.parse(localStorage.getItem('user'))
          : { token: '' },
      }
  }
}

const store = createStore(changeState)
export default store
