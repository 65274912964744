const defaultv = 'eyJudWwiOm51bGx9'
const encpt = ({ st = '', key = null, typ = false, hash = '' }) => {
  switch (typ) {
    case 'session':
      return sessionStorage.setItem(key, `eyJsc==$${btoa(st)}${hash}==`)
    case 'localest':
      return localStorage.setItem(key, `eyJsc==$${btoa(st)}${hash}==`)

    default:
      break
  }
}
const decpt = ({ key = null, typ = false, hash = '' }) => {
  var dt = null
  var dc = null
  try {
    switch (typ) {
      case 'session':
        dt = sessionStorage.getItem(key) || `eyJsc==$${defaultv}${hash}==`
        // console.log('dt', dt, '=========', dt.slice(8, -(2 + hash.length)))
        dc = atob(dt.slice(8, -(2 + hash.length)))
        // console.log('dc', dc)
        return dc
      case 'localest':
        dt = localStorage.getItem(key) || `eyJsc==$${defaultv}${hash}==`
        dc = atob(dt.slice(8, -(2 + hash.length)))
        return dc

      default:
        break
    }
  } catch (error) {
    // console.log('error', error)
    switch (typ) {
      case 'session':
        sessionStorage.removeItem(key)
        break
      case 'localest':
        localStorage.removeItem(key)
        break

      default:
        break
    }
  }
}
const restringfy = (data) => {
  return JSON.stringify(JSON.parse(data))
}

export { encpt, decpt, restringfy }
